import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
  height: auto;
  margin: 0 auto;
  position: absolute;
  padding: 65px 0px 0px 0px;
  text-align: center;
  z-index: 50;
  @media (max-width: 991px) {
    width: 100%;
    height: auto;
    padding: 35px 35px 10px 35px;
    text-align: center;
    position: absolute;
  }
  a {
    cursor: pointer;
  }
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: #00a6e8;
`;

export const ImageStyle = styled.img`
  width: 100%;
  height: auto;
  max-width: 260px;
  height: auto;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    display: block;
    max-width: 200px;
    height: auto;
    padding-top: 0px;
    padding-bottom: 30px;
    margin: 0 auto;
  }
`;
export const Heading = styled.h1`
  text-align: center;
  position: relative;
  font-family: 'Figtree-Black', arial, sans-serif;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: #55b34c;
  letter-spacing: 3px;
  code {
    color: #ffc905;
    font-family: 'Figtree-Black', arial, sans-serif;
    font-size: 4vw;
    line-height: 4vw;
  }
  @media (max-width: 1199px) {
    font-size: 20px;
    line-height: 25px;
    code {
      font-size: 74px;
      line-height: 74px;
      color: #ffc905;
      font-family: 'Figtree-Black', arial, sans-serif;
    }
  }
  @media (max-width: 991px) {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 30px;
    code {
      font-size: 45px;
      line-height: 45px;
      color: #ffc905;
      font-family: 'Figtree-Black', arial, sans-serif;
    }
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
    code {
      font-size: 36px;
      line-height: 100%;
      color: #ffc905;
      font-family: 'Figtree-Black', arial, sans-serif;
    }
  }
`;
export const ParaText = styled.p`
  margin-bottom: 40px;
  color: #55b34c;
  font-family: 'Figtree-Black', arial, sans-serif;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 3px;
  text-transform: uppercase;
  @media (max-width: 1399px) {
    margin-bottom: 30px;
  }
  a {
    i {
      font-size: inherit;
      line-height: inherit;
    }
  }
  i {
    font-style: italic;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 1399px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
      line-height: 20px;
    }
  }
`;
export const TitleImage = styled.img`
  max-width: 260px;
  height: auto;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    display: block;
    max-width: 200px;
    height: auto;
    padding-top: 0px;
    padding-bottom: 30px;
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    display: block;
    max-width: 100px;
    height: auto;
    padding-top: 0px;
    padding-bottom: 30px;
    margin: 0 auto;
  }
`;
