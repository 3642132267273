import React from 'react';
import * as Styled from './slpWelcomeTitleStyles';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';
import useContentfulAssets from '../../hooks/useContentfulAssets';

const SlpWelcomeTitle = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.Heading>{children}</Styled.Heading>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node.data.target.sys.id);
        return asset.node.file.url.includes('image') ? (
          <Styled.TitleImage src={asset.node.file.url} alt={asset.node.title} />
        ) : (
          <></>
        );
      },
    },
    renderMark: {
      [MARKS.Code]: (node) => <div>{node}</div>,
    },
    renderText: (text) =>
      text?.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />]),
  };
  return (
    <>
      <Styled.TitleContainer>
        <a
          href={
            isExternalUrlHref(sectionData?.path)
              ? sectionData?.path
              :addTrailingSlash(  
                process.env.GATSBY_DOMAIN_URL + sectionData?.path
              )
          }
          target="_blank"
          rel="noreferrer"
        >
          <Styled.ImageStyle
            src={sectionData?.image?.file?.url}
          ></Styled.ImageStyle>
        </a>
        {sectionData?.title && (
          <Styled.Heading>{sectionData?.title}</Styled.Heading>
        )}
        {sectionData?.richText &&
          documentToReactComponents(
            JSON.parse(sectionData?.richText?.raw),
            optionsMainStyle
          )}
      </Styled.TitleContainer>
    </>
  );
};
export default SlpWelcomeTitle;
